<template>
  <div class="d-block">
    <d-button
        :text="'general.actions.edit'"
        :class="'ml-2 disabled d-btn-sm btn d-btn-primary-outline font-text-title'"
        icon="icofont icofont-ui-edit mr-1"
    />
  </div>
</template>
<script>
export default {

}
</script>
